<template>
  <BaseButton
    :class="[rounded, isSecondary ? 'border border-black bg-gray-900 hover:border-gray-500 hover:bg-gray-800 text-white hover:text-gray-100' : 'bg-custom-purple-500 hover:underscore text-white']"
    :no-padding="noPadding"
    :no-shadow="noShadow"
    :bold="bold"
    :locked="locked"
    @click="$emit('click', $event)"
  >
    <slot />
  </BaseButton>
</template>
  
  <script>
  
  export default {
	name: 'NeutralButton',
	props: {
      eucapsTheme: { type: Boolean, default: false },
      secondary: { type: Boolean, default: false },
      noPadding: { type: Boolean, default: false },
      noShadow: { type: Boolean, default: false },
      bold: { type: Boolean, default: true },
      locked: { type: Boolean, default: false },
      rounded: { type: String, default: 'rounded-md' }
	},
	computed: {
      isSecondary () {
		return (this.secondary !== false)
      }
	}
  }
  </script>
  