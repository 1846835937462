import { get, post, downloadBlob } from "../helpers/axiosHelper";

const url = `${process.env.VUE_APP_API_URL}`;

const fetchDocuments = async (companyId) => get(`${url}/admin/companies/${companyId}/documents`);
const downloadDocument = async (payload) => downloadBlob(`${url}/admin/companies/${payload.companyId}/documents/legalDcouments/${payload.documentId}/download`);
const fetchIncidents = async (companyId) => get(`${url}/admin/companies/${companyId}/incidents`);
const fetchInvoices = async (companyId) => get(`${url}/admin/companies/${companyId}/invoices/get-invoices`);
const downloadInvoice = async (payload) => get(`${url}/admin/companies/${payload.companyId}/invoices/download/${payload.invoiceId}`);
const fetchCompanyUsers = async (payload) => post(`${url}/admin/companies/${payload.companyId}/user-and-roles/fetch-company-users`, payload);
const addRole = async (payload) => post(`${url}/admin/companies/${payload.companyId}/user-and-roles/users/${payload.userId}/add-role`);
const removeRole = async (payload) => post(`${url}/admin/companies/${payload.companyId}/user-and-roles/users/${payload.userId}/remove-role`);
const searchUser = async (payload) => post(`${url}/admin/search-user`, payload);

export default {
    fetchDocuments,
    downloadDocument,
    fetchIncidents,
    fetchInvoices,
    downloadInvoice,
    fetchCompanyUsers,
    addRole,
    removeRole,
    searchUser
}
