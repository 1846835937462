<template>
  <Sidebar
    :show-sidebar="showSidebar"
    :current="current"
    :green="green"
    :purple="purple"
    :stock-user="usersStore?.user?.profile"
    :user="usersStore.user"
    :current-route="currentRoute"
    @toggle-sidebar="toggleSidebar"
    @change-color="changeColor"
    @reset-color="resetColor"
    @select-item="selectItem"
    @sign-in="signIn"
    @sign-out="signOut"
  />
</template>
<script>
import { ref } from "vue";
import Sidebar from "./_components/Sidebar.vue";
import { useUsersStore } from "@/stores/users";
import router from "../../js/router";
export default {
  name: "SidebarModule",
  components: {
    Sidebar,
  },
  setup() {
    const usersStore = useUsersStore()
    const showSidebar = ref(false);
    const current = ref("");
    const green = ref(false);
    const purple = ref(true);
    const selectedItem = ref("");

    function toggleSidebar() {
      showSidebar.value = !showSidebar.value;
    }
    function changeColor(e) {
      current.value = e.target.id;
      green.value = true;
      purple.value = false;
    }
    function resetColor() {
      green.value = false;
      purple.value = true;
    }
    function selectItem(e) {
      selectedItem.value = e.target.id;
      green.value = true;
      purple.value = false;
    }

    return {
      toggleSidebar,
      changeColor,
      resetColor,
      showSidebar,
      current,
      green,
      purple,
      selectedItem,
      selectItem,
      usersStore
    };
  },
  computed: {
    currentRoute() {
      console.log(this.$route.fullPath)
      return this.$route.fullPath;
    }
  },
  methods: {
    signIn() {
      router.push({ name: 'login' })
    },
    signOut() {
      this.usersStore.logout()
    }
  }
};
</script>
