<template>
  <div :class="(upperClass) ? 'relative w-full border border-gray-300 dark:border-custom-purple-300 rounded-sm bg-white' : ''">
    <select
      :required="required"
      :value="value"
      :class="[classes, padding, 'appearance-none']"
      class=" text-custom-purple-500"
      @input="$emit('input', $event)"
    >
      <slot />
    </select>
  </div>
</template>

<script>

export default {
  name: 'BaseSelect',
  props: {
    required: { type: Boolean, default: false },
    value: { type: String, default: '', required: true },
    classes: { type: String, default: 'w-full rounded-md bg-transparent border-none' },
    padding: { type: String, default: 'px-4 py-2' },
    upperClass: { type: Boolean, default: false }
  },
  emits: ['input']
}
</script>
<style scoped>
</style>
