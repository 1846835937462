<template>
  <div
    id="stickyTopMenu"
    ref="stickyTopMenu"
    class=" w-full shadow-sm dark:shadow-sm-dark z-60 sticky top-0 dark:border-custom-purple-500"
    :class="[secondary ? 'bg-custom-purple-500 text-white shadow-none' : 'bg-white dark:bg-custom-purple-800', isApple && addPadding? 'pt-4': '']"
  >
    <slot />
  </div>
</template>

<script>
import { isIphone } from '@/js/helpers/mobile'
import { Capacitor } from '@capacitor/core'

export default {
  name: 'HorizontalSubMenu',
  props: {
    secondary: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      addPadding: false,
      scrollPosition: null
    }
  },
  computed: {
    isApple () {
      return Capacitor.getPlatform() === 'ios' || isIphone()
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollAddPadding, false)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.scrollAddPadding, false)
  },
  methods: {
    scrollAddPadding () {
      const self = this
      const element = self.$refs.stickyTopMenu
      const elementTop = element.getBoundingClientRect().top

      window.addEventListener('scroll', () => {
        if (window.pageYOffset >= elementTop) {
          self.addPadding = true
        } else {
          self.addPadding = false
        }
      })
    }
  }

}
</script>
