import {get, post, put} from "../helpers/axiosHelper"

const url = `${process.env.VUE_APP_API_URL}`

const findUserWithRoles = async(data) => post(`${url}/roles/search-user-with-roles`, data)
const getAllRoles = async()=> get(`${url}/roles`)
const assignRolesToUser = async(data) => post(`${url}/roles/assign-to-user/users/${data.userId}`, {"roleIds": [...data.query]})
const removeRolesFromUser = async(data) => post(`${url}/roles/remove-from-user/users/${data.userId}`, {"roleIds": [...data.query]})
const getAllDomains = async() => get(`${url}/domains`)
const createDomain = async(data) => post(`${url}/domains`, { name: data })
const updateDomainName = async(data) => put(`${url}/domains/${data.id}/update`, { name: data.name })
const assignRolesToDomain = async(data) => post(`${url}/domains/${data.id}/assign-roles`, {"roleIds": [...data.query]})
const removeRolesFromDomain = async(data) => post(`${url}/domains/${data.id}/remove-roles`, {"roleIds": [...data.query]})
const searchRoles = async(data)=> post(`${url}/roles/search`, data)
const createRole = async(data)=> post(`${url}/roles`, data)
const updateRole = async(data)=> put(`${url}/roles/${data.id}/update`, data)

export default {
    findUserWithRoles,
    getAllRoles,
    assignRolesToUser,
    removeRolesFromUser,
    getAllDomains,
    createDomain,
    updateDomainName,
    assignRolesToDomain,
    removeRolesFromDomain,
    searchRoles,
    createRole,
    updateRole
}

