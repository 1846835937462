<template>
  <div class="w-full flex flex-col flex-1 mb-32 h-full lg:px-32">
    <ColumnWrapper
      row
      class="gap-4"
    >
      <LeftColumn
        small
        class="max-w-[350px] min-w-[350px] hidden xl:block"
      >
        <SidebarContainer />
      </LeftColumn>
      <RightColumn full>
        <div>
          <SidebarContainer class="xl:hidden block sidebar top-0 left-0 bottom-0 fixed z-[999]" />
          <div class="content">
            <HeaderContainer />

            <div class="px-4 py-8">
              <RouterView :key="$route.fullPath" />
            </div>
          </div>
        </div>
      </RightColumn>
    </ColumnWrapper>
    <PortalTarget
      name="dialog"
      multiple
    />
  </div>
</template>

<script>
import SidebarContainer from "@/modules/sidebar";
import HeaderContainer from "@/modules/header";
import { PortalTarget } from "vue3-portal";

export default {
  name: "Layout",
  components: {
    HeaderContainer,
    PortalTarget,
    SidebarContainer,
  },
};
</script>

<style scoped>
.sidebar {
  -webkit-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

.content {
  -webkit-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}
</style>