<template>
  <div>
    <button
      data-drawer-target="default-sidebar"
      data-drawer-toggle="default-sidebar"
      aria-controls="default-sidebar"
      type="button"
      :class="!showSidebar ? 'hidden' : ''"
      class="cursor-pointer inline-flex items-center p-2 mt-4 ml-2 text-sm text-gray-500 rounded-lg hover:bg-custom-purple-200 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      @click="$emit('toggle-sidebar')"
    >
      <Icon
        name="menu"
        class="text-3xl fixed pb-4"
      />
    </button>

    <aside
      id="default-sidebar"
      class="flex float-right xl:my-8 xl:block xl:top-4 xl:left-4 top-0 left-0 z-50 w-full xl:w-80 xl:h-auto h-screen mb-20"
      aria-label="Sidebar"
      :class="{ hidden: showSidebar }"
    >
      <div
        class="h-full py-4 xl:rounded-xl overflow-y-auto bg-custom-purple-500 text-white dark:bg-custom-purple-300"
      >
        <ul class="space-y-2 font-medium">
          <li class="flex justify-between px-2">
            <a
              href="#"
              class="flex py-2 text-white rounded-lg dark:text-white dark:hover:bg-gray-700"
            >
              <div class="h-10 ml-4 rounded-md">
                <Avatar :stock-user="user?.profile" />
              </div>
              <div>
                <p class="px-4 whitespace-nowrap">
                  {{ user?.profile?.name ? user?.profile?.name : "Guest User" }}
                </p>
                <p class="px-4 text-sm">{{ user?.email }}</p>
              </div>
            </a>
            <div>
              <Icon
                name="x"
                class="xl:hidden px-2 text-4xl text-white dark:text-custom-purple-100 cursor-pointer"
                @click="$emit('toggle-sidebar')"
              />
            </div>
          </li>
          <div class="bg-custom-purple-300 h-px" />
          <!-- <li class="px-2">
            <div class="flex items-center px-4 py-2 text-white rounded-lg">
              <div class="relative w-full">
                <div
                  class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                >
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5 text-white"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  id="simple-search"
                  type="text"
                  class="placeholder-white placeholder-text-sm border-none bg-custom-purple-300 text-white text-sm rounded-lg focus:ring-blue-500 block h-12 w-full pl-10 p-2"
                  placeholder="Find in menu"
                >
              </div>
            </div>
          </li> -->
          <!-- <div class="bg-custom-purple-300 h-px" /> -->
          <li>
            <RouterLink
              id="monitoring"
              :to="{name: 'monitoring'}"
              exact
              class="mt-6 hover:border-l-4 border-custom-green-500 flex px-7 py-2 hover:bg-custom-purple-400 hover:text-custom-green-500 odd:text-custom-purple-300 even:text-white"
             
              @mouseover="$emit('change-color', $event)"
              @mouseout="$emit('reset-color')"
              @click="$emit('select-item', $event)"
            >
              <SvgImage
                :purple="purple"
                :green="
                  (current === 'monitoring' && green) ||
                    currentRoute.includes('monitoring')
                "
                src="/svg/monitoring.svg"
                class="w-4 h-4 mt-1 cursor-pointer"
              />
              <div
                id="monitoring"
                class="whitespace-nowrap ml-3 text-white text-base cursor-pointer"
              >
                Monitoring
              </div>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              id="development"
              :to="{name: 'development'}"
              class="hover:border-l-4 border-custom-green-500 flex px-7 py-2 hover:bg-custom-purple-400 hover:text-custom-green-500 odd:text-custom-purple-300 even:text-white"
              @mouseover="$emit('change-color', $event)"
              @mouseout="$emit('reset-color')"
              @click="$emit('select-item', $event)"
            >
              <SvgImage
                :purple="purple"
                :green="
                  (current === 'development' && green) ||
                    currentRoute.includes('development')
                "
                src="/svg/development.svg"
                class="w-4 h-4 mt-1 cursor-pointer"
              />
              <div
                id="development"
                class="whitespace-nowrap ml-3 text-white text-base cursor-pointer"
              >
                Development
              </div>
            </RouterLink>
          </li>

          <li>
            <RouterLink
              id="customer-care"
              :to="{name: 'customerCare'}"
              class="hover:border-l-4 border-custom-green-500 flex px-7 py-2 hover:bg-custom-purple-400 hover:text-custom-green-500 odd:text-custom-purple-300 even:text-white"
              @mouseover="$emit('change-color', $event)"
              @mouseout="$emit('reset-color')"
              @click="$emit('select-item', $event)"
            >
              <SvgImage
                :purple="purple"
                :green="
                  (current === 'customer-care' && green) ||
                    currentRoute.includes('customer-care')
                "
                src="/svg/customer-care.svg"
                class="w-4 h-4 mt-1 cursor-pointer"
              />
              <div
                id="customer-care"
                class="whitespace-nowrap ml-3 text-white text-base cursor-pointer"
              >
                Customer care
              </div>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              id="incidents"
              to="/incidents"
              class="hover:border-l-4 border-custom-green-500 flex px-7 py-2 hover:bg-custom-purple-400 hover:text-custom-green-500 odd:text-custom-purple-300 even:text-white" 
              @mouseover="$emit('change-color', $event)"
              @mouseout="$emit('reset-color')"
              @click="$emit('select-item', $event)"
            >
              <SvgImage
                :purple="purple"
                :green="
                  (current === 'incidents' && green) ||
                    currentRoute.includes('incidents')
                "
                src="/svg/incident.svg"
                class="w-4 h-4 mt-1 cursor-pointer"
              />
              <div
                id="incidents"
                class="whitespace-nowrap ml-3 text-white text-base cursor-pointer"
              >
                Incidents
              </div>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              id="companies"
              to="/companies"
              class="hover:border-l-4 border-custom-green-500 flex px-7 py-2 hover:bg-custom-purple-400 hover:text-custom-green-500 odd:text-custom-purple-300 even:text-white"
              @mouseover="$emit('change-color', $event)"
              @mouseout="$emit('reset-color')"
              @click="$emit('select-item', $event)"
            >
              <SvgImage
                :purple="purple"
                :green="
                  (current === 'companies' && green) ||
                    currentRoute.includes('companies')
                "
                src="/svg/companies.svg"
                class="w-4 h-4 mt-1 cursor-pointer"
              />
              <div
                id="companies"
                class="whitespace-nowrap ml-3 text-white text-base cursor-pointer"
              >
                Companies
              </div>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              id="poll"
              to="/poll-campaign"
              class="hover:border-l-4 border-custom-green-500 flex px-7 py-2 hover:bg-custom-purple-400 hover:text-custom-green-500 odd:text-custom-purple-300 even:text-white"
              @mouseover="$emit('change-color', $event)"
              @mouseout="$emit('reset-color')"
              @click="$emit('select-item', $event)"
            >
              <SvgImage
                :purple="purple"
                :green="
                  (current === 'poll' && green) ||
                    currentRoute.includes('poll')
                "
                src="/svg/poll.svg"
                class="w-4 h-4 mt-1 cursor-pointer"
              />
              <div
                id="poll"
                class="whitespace-nowrap ml-3 text-white text-base cursor-pointer"
              >
                Poll Stats
              </div>
            </RouterLink>
          </li>
          <!-- <li>
            <a
              id="trend"
              class="hover:border-l-4 border-custom-green-500 flex px-7 py-2 hover:bg-custom-purple-400 hover:text-custom-green-500 odd:text-custom-purple-300 even:text-white"
              :class="
                currentFeature === 'trend' && 'border-l-4 border-custom-green-500'
              "
              @mouseover="$emit('change-color', $event)"
              @mouseout="$emit('reset-color')"
              @click="$emit('select-item', $event)"
            >
              <SvgImage
                :purple="purple"
                :green="
                  (current === 'trend' && green) || currentFeature === 'trend'
                "
                src="/svg/users.svg"
                class="w-4 h-4 mt-1 cursor-pointer"
              />
              <div
                id="trend"
                class="whitespace-nowrap ml-3 text-white text-base cursor-pointer"
              >
                Trend of users
              </div>
            </a>
          </li> -->
          <!-- <li>
            <a
              id="compliance"
              class="hover:border-l-4 border-custom-green-500 flex px-7 py-2 hover:bg-custom-purple-400 hover:text-custom-green-500 odd:text-custom-purple-300 even:text-white"
              :class="
                currentFeature === 'compliance' &&
                  'border-l-4 border-custom-green-500'
              "
              @mouseover="$emit('change-color', $event)"
              @mouseout="$emit('reset-color')"
              @click="$emit('select-item', $event)"
            >
              <SvgImage
                :purple="purple"
                :green="
                  (current === 'compliance' && green) ||
                    currentFeature === 'compliance'
                "
                src="/svg/heart.svg"
                class="w-4 h-4 mt-1 cursor-pointer"
              />
              <div
                id="compliance"
                class="whitespace-nowrap ml-3 text-white text-base cursor-pointer"
              >
                Compliance
              </div>
            </a>
          </li> -->
          <!-- <li>
            <a
              id="marketing"
              class="hover:border-l-4 border-custom-green-500 flex px-7 py-2 hover:bg-custom-purple-400 hover:text-custom-green-500 odd:text-custom-purple-300 even:text-white"
              :class="
                currentFeature === 'marketing' &&
                  'border-l-4 border-custom-green-500'
              "
              @mouseover="$emit('change-color', $event)"
              @mouseout="$emit('reset-color')"
              @click="$emit('select-item', $event)"
            >
              <SvgImage
                :purple="purple"
                :green="
                  (current === 'marketing' && green) ||
                    currentFeature === 'marketing'
                "
                src="/svg/marketing.svg"
                class="w-4 h-4 mt-1 cursor-pointer"
              />
              <div
                id="marketing"
                class="whitespace-nowrap ml-3 text-white text-base cursor-pointer"
              >
                Marketing panel
              </div>
            </a>
          </li> -->
          <!-- <li>
            <a
              id="product"
              class="hover:border-l-4 border-custom-green-500 flex px-7 py-2 hover:bg-custom-purple-400 hover:text-custom-green-500 odd:text-custom-purple-300 even:text-white"
              :class="
                currentFeature === 'product' &&
                  'border-l-4 border-custom-green-500'
              "
              @mouseover="$emit('change-color', $event)"
              @mouseout="$emit('reset-color')"
              @click="$emit('select-item', $event)"
            >
              <SvgImage
                :purple="purple"
                :green="
                  (current === 'product' && green) || currentFeature === 'product'
                "
                src="/svg/cube.svg"
                class="w-4 h-4 mt-1 cursor-pointer"
              />
              <div
                id="product"
                class="whitespace-nowrap ml-3 text-white text-base cursor-pointer"
              >
                Product stats
              </div>
            </a>
          </li> -->
          <li>
            <a
              id="finance"
              class="hover:border-l-4 border-custom-green-500 flex px-7 py-2 hover:bg-custom-purple-400 hover:text-custom-green-500 odd:text-custom-purple-300 even:text-white"
              :class="
                currentFeature === 'finance' &&
                  'border-l-4 border-custom-green-500'
              "
              @mouseover="$emit('change-color', $event)"
              @mouseout="$emit('reset-color')"
              @click="$emit('select-item', $event)"
            >
              <SvgImage
                :purple="purple"
                :green="
                  (current === 'finance' && green) || currentFeature === 'finance'
                "
                src="/svg/finance.svg"
                class="w-4 h-4 mt-1 cursor-pointer"
              />
              <div
                id="finance"
                class="whitespace-nowrap ml-3 text-white text-base cursor-pointer"
              >
                Finance
              </div>
            </a>
          </li>
          <li>
            <RouterLink
              id="sales"
              to="/sales-manager"
              class="hover:border-l-4 border-custom-green-500 flex px-7 py-2 hover:bg-custom-purple-400 hover:text-custom-green-500 odd:text-custom-purple-300 even:text-white"
              :class="
                currentFeature === 'sales' && 'border-l-4 border-custom-green-500'
              "
              @mouseover="$emit('change-color', $event)"
              @mouseout="$emit('reset-color')"
              @click="$emit('select-item', $event)"
            >
              <SvgImage
                :purple="purple"
                :green="
                  (current === 'sales' && green) || currentRoute.includes('sales-manager')
                "
                src="/svg/sales.svg"
                class="w-4 h-4 mt-1 cursor-pointer"
              />
              <div
                id="sales"
                class="whitespace-nowrap ml-3 text-white text-base cursor-pointer"
              >
                Sales
              </div>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              id="salesOfficer"
              to="/sales-officer"
              class="hover:border-l-4 border-custom-green-500 flex px-7 py-2 hover:bg-custom-purple-400 hover:text-custom-green-500 odd:text-custom-purple-300 even:text-white"
              @mouseover="$emit('change-color', $event)"
              @mouseout="$emit('reset-color')"
              @click="$emit('select-item', $event)"
            >
              <SvgImage
                :purple="purple"
                :green="
                  (current === 'salesOfficer' && green) ||
                    currentRoute.includes('sales-officer')
                "
                src="/img/manager.png"
                class="w-5 h-5 mt-1 cursor-pointer"
              />
              <div
                id="salesOfficer"
                class="whitespace-nowrap ml-3 text-white text-base cursor-pointer"
              >
                Sales Officer
              </div>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              id="roles"
              to="/roles"
              class="hover:border-l-4 border-custom-green-500 flex px-7 py-2 hover:bg-custom-purple-400 hover:text-custom-green-500 odd:text-custom-purple-300 even:text-white"
              :class="
                currentFeature === 'roles' && 'border-l-4 border-custom-green-500'
              "
              @mouseover="$emit('change-color', $event)"
              @mouseout="$emit('reset-color')"
              @click="$emit('select-item', $event)"
            >
              <SvgImage
                :purple="purple"
                :green="
                  (current === 'roles' && green) || currentRoute.includes('roles')
                "
                src="/svg/users.svg"
                class="w-4 h-4 mt-1 cursor-pointer"
              />
              <div
                id="roles"
                class="whitespace-nowrap ml-3 text-white text-base cursor-pointer"
              >
                Roles
              </div>
            </RouterLink>
          </li>
          <li>
            <RouterLink
              id="scriptMonitoring"
              to="/script"
              class="hover:border-l-4 border-custom-green-500 flex px-7 py-2 hover:bg-custom-purple-400 hover:text-custom-green-500 odd:text-custom-purple-300 even:text-white"
              :class="
                currentFeature === 'scriptMonitoring' && 'border-l-4 border-custom-green-500'
              "
              @mouseover="$emit('change-color', $event)"
              @mouseout="$emit('reset-color')"
              @click="$emit('select-item', $event)"
            >
              <SvgImage
                :purple="purple"
                :green="
                  (current === 'scriptMonitoring' && green) || currentRoute.includes('script')
                "
                src="/svg/users.svg"
                class="w-4 h-4 mt-1 cursor-pointer"
              />
              <div
                id="scriptMonitoring"
                class="whitespace-nowrap ml-3 text-white text-base cursor-pointer"
              >
                Script Monitoring
              </div>
            </RouterLink>
          </li>
          <div class="lg:pt-16 pt-8">
            <!-- <li>
              <a
                id="settings"
                class="hover:border-l-4 border-custom-green-500 flex px-7 py-2 hover:bg-custom-purple-400 hover:text-custom-green-500 odd:text-custom-purple-300 even:text-white"
                :class="
                  currentFeature === 'settings' &&
                    'border-l-4 border-custom-green-500'
                "
                @mouseover="$emit('change-color', $event)"
                @mouseout="$emit('reset-color')"
                @click="$emit('select-item', $event)"
              >
                <SvgImage
                  :purple="purple"
                  :green="
                    (current === 'settings' && green) ||
                      currentFeature === 'settings'
                  "
                  src="/svg/settings.svg"
                  class="w-4 h-4 mt-1 cursor-pointer"
                />
                <div
                  id="settings"
                  class="whitespace-nowrap ml-3 text-white text-base cursor-pointer"
                >
                  Settings
                </div>
              </a>
            </li> -->
            <li>
              <a
                id="sign-out"
                class="hover:border-l-4 border-custom-green-500 flex px-7 py-2 hover:bg-custom-purple-400 hover:text-custom-green-500 odd:text-custom-purple-300 even:text-white"
                :class="
                  currentFeature === 'sign-out' &&
                    'border-l-4 border-custom-green-500'
                "
                @mouseover="$emit('change-color', $event)"
                @mouseout="$emit('reset-color')"
                @click="$emit(user ? 'sign-out' : 'sign-in', $event)"
              >
                <SvgImage
                  :purple="purple"
                  :green="
                    (current === 'sign-out' && green) ||
                      currentFeature === 'sign-out'
                  "
                  src="/svg/sign-out.svg"
                  class="w-4 h-4 mt-1 cursor-pointer"
                />
                <div
                  id="sign-out"
                  class="whitespace-nowrap ml-3 text-white text-base cursor-pointer"
                  @click="$emit('sign-out')"
                >
                  Sign out
                </div>
              </a>
            </li>
          </div>
        </ul>
      </div>
    </aside>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  props: {
    showSidebar: {
      type: Boolean,
      default: false,
      required: true,
    },
    current: {
      type: String,
      default: "",
      required: true,
    },
    green: {
      type: Boolean,
      required: true,
    },
    purple: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      default: () => {},
    },
    currentRoute: {
      type: String,
      default: "",
    },
  },
  emits: [
    "change-color",
    "reset-color",
    "select-item",
    "sign-in",
    "sign-out",
    "toggle-sidebar",
  ],
};
</script>

<style lang="scss">
.router-link-active {
  @apply border-l-4 border-custom-green-500;
}
</style>