<template>
  <CircleLoader />
</template>

<script>

export default {
  name: 'Loader'
}
</script>
