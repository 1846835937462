<template>
  <div
    class="flex items-center justify-between" 
  >
    <div class="flex flex-row items-center justify-start">
      <Avatar :stock-user="user.profile" />
      <div class="flex flex-col ml-1">
        <p class="font-semibold">
          {{ user.profile.full_name }}
        </p>
        <div class="text-custom-gray-300 text-sm font-light flex">
          <p class="ml-1">
            {{ user.email }}
          </p>
        </div>
      </div>
    </div>
    <Icon
      v-if="showCancel"
      name="x-circle"
      class="text-custom-red-400 text-xl mr-2 cursor-pointer"
      @click="$emit('cancel')"
    />
  </div>
</template>
<script>
export default {
  name: 'UserListItem',
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['cancel'],
}
</script>
