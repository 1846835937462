import { destroy, get, post, downloadBlob } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/admin`
const createPollCampaign = async (companyId, data) => post(`${url}/companies/${companyId}/create-poll-campaign`, data)
const fetchAll = async (data) => get(`${url}/fetch-all-poll-campaigns`, data)
const fetch = async (url, data)=>get(url,data)
const fetchPollCampaign = async (id) => get(`${url}/poll-campaign/${id}/fetch`)
const fetchPollCampaignChartData = async (id) => get(`${url}/poll-campaign/${id}/fetch-chart-data`)
const deletePollCampaign = async (id) => destroy(`${url}/poll-campaign/${id}/delete`)
const updatePollCampaign = async (companyId, pollId, data) => post(`${url}/companies/${companyId}/poll-campaign/${pollId}/update`, data)
const fetchStatistics = async () => get(`${url}/poll-campaign-statistics`)
const downloadReport = async (id) => downloadBlob(`${url}/poll-campaign/${id}/download-report`)

export default {
  downloadReport,
  updatePollCampaign,
  createPollCampaign,
  fetchAll,
  fetch,
  fetchPollCampaign,
  deletePollCampaign,
  fetchStatistics,
  fetchPollCampaignChartData
}
