<template>
  <div>
    <p class="text-base whitespace-pre-wrap break-words leading-relaxed pr-4 dark:text-custom-purple-100">
      <v-runtime-template :template="`<div>${internalMessage}</div>`" />
    </p>
    <TranslateToggle
      v-if="hasEnglish"
      :translate="translateInternal"
      @toggle="translateText"
    />
  
    <LinkPreview
      v-for="(url, index) in links"
      :key="url + index"
      :url="url"
    />
  </div>
</template>
  
  <script>
  import VRuntimeTemplate from "vue3-runtime-template"
  import linkHandler from '@/js/helpers/links'
  import stringHandler from '@/js/helpers/string'
  export default {
	name: 'TextMessage',
	components: {
      VRuntimeTemplate
	},
	props: {
      message: { type: String, default: '' },
      messageEnglish: { type: String, default: '' },
      showEnglish: { type: String, default: null },
      truncate: { type: Boolean, default: false },
      translate: { type: Boolean, default: false },
      minified: { type: Boolean, default: false },
      truncateSize: { type: Number, default: 50 }
	},
	data () {
      return {
		template: '',
		translateInternal: false,
		links: []
      }
	},
	computed: {
      internalMessage () {
		if (this.translateInternal && this.hasEnglish) {
          return this.handleMessage(this.messageEnglish)
		}
  
		return this.handleMessage(this.message)
      },
      hasEnglish () {
		return this.messageEnglish && this.messageEnglish.length > 0
      }
	},
	mounted () {
      this.translateInternal = this.translate
	},
	methods: {
      redirect (data) {
		// this.$emit('redirect', data)
		this.$router.push({ name: data.name, params: { id: data.id } })
      },
      maxText (text) {
		return stringHandler.maxLength(text, 50)
      },
      handleMessage (message) {
		const data =
          this.truncate && message.length > this.truncateSize
			? message.replace('<p>', '').slice(0, this.truncateSize) + '...'
			: message
		const links = linkHandler.linkable(data)
		this.links = links.links
		return links.newText
      },
      translateText () {
		this.translateInternal = !this.translateInternal
		this.$emit('translate', this.translateInternal)
      }
	}
  }
  </script>
  