<template>
  <div class="font-extrabold text-2xl">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'BigTitle'
}
</script>
