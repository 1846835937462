<template>
  <div class="p-4 h-full w-full">
    <div class="h-full w-full rounded-lg shadow bg-custom-purple-500 py-2 px-4 text-white">
      <nav class="relative flex items-center justify-between py-2 mx-auto">
        <div class="flex items-center">
          <LogoContainer
            eucaps
            class="mr-4"
          />
        </div>
        <div class="flex items-center text-white font-medium">
          <div
            class="cursor-pointer"
            @click="showMyEucapsMenu = !showMyEucapsMenu"
          >
            <Avatar
              :size="30"
              :stock-user="usersStore.user && usersStore.user.profile ? usersStore.user.profile : null"
            />
          </div>
        </div>
      </nav>
      <RouterLink
        to="/"
        class="px-4 py-2 block text-lg font-extrabold"
      >
        Monitor
      </RouterLink>
      <RouterLink
        to="/"
        class="px-4 py-2 block text-lg font-extrabold"
      >
        My Sales
      </RouterLink>
      <RouterLink
        to="/roles"
        class="px-4 py-2 block text-lg font-extrabold"
      >
        <div class="flex items-center">
          <Icon
            solid
            class="mr-2 text-custom-green-300"
            name="purchase-tag-alt"
          />
          Roles
        </div>
      </RouterLink>
      <RouterLink
        to="/poll-campaign"
        class="px-4 py-2 block text-lg font-extrabold"
      >
        Poll Stats
      </RouterLink>
    </div>
  </div>
</template>

<script>
import LogoContainer from '@/modules/logo'
import { useUsersStore } from '@/stores/users'
export default {
  name: 'LeftMenu',
  components: {
    LogoContainer
  },
  setup () {
    const usersStore = useUsersStore()

    return { usersStore }
  },

}
</script>
