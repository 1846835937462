import { createApp, h } from 'vue'

import App from './App.vue'
import router from './js/router'
// import { i18n } from '@/js/language/'
// import store from './js/store'
import { createPinia } from 'pinia'
import '@/assets/css/tailwind.scss'
import { registerComponents } from './js/autoload'
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

import './registerServiceWorker'
import Portal from 'vue3-portal'


// import { StatusBar, Animation } from '@capacitor/status-bar'

// StatusBar.setStyle({ animation: Animation.SLIDE })
// StatusBar.setBackgroundColor({ color: '#543db2' })
import HighchartsVue from 'highcharts-vue'
// import Highcharts from 'highcharts'
// import Stock from 'highcharts/modules/stock'
// import HighchartsMore from 'highcharts/highcharts-more'
// import HighchartsExporting from 'highcharts/modules/exporting'

const app = createApp({
  setup: () => () => (
    h(App)
  )
})

// app.use(i18n)
// Stock(Highcharts)
// HighchartsExporting(Highcharts)
// HighchartsMore(Highcharts)
app.use(HighchartsVue)
app.use(createPinia())
app.use(router)
app.use(Portal)
app.component('Multiselect', Multiselect)

registerComponents(app)

app.mount('#app')