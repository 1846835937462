<template>
  <div class="logo font-header font-bold flex flex-col text-center items-center text-custom-light-header dark:text-custom-dark-header">
    <img
      :class="small ? 'w-40 mb-2' : 'w-40'"
      src="/logo.svg"
    >
  </div>
</template>

<script>

export default {
  name: 'Logo',
  props: {
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>
