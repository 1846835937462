<template>
  <div class="flex flex-row items-center justify-center">
    <Icon
      v-if="!value"
      name="radio-circle"
      class=" text-custom-purple-500 text-2xl cursor-pointer"
      @click="$emit('click', $event)"
    />
    <Icon
      v-if="value"
      name="radio-circle-marked"
      class=" text-custom-orange-400 text-2xl cursor-pointer"
      @click="$emit('click', $event)"
    />
    <span class="text-custom-purple-500 text-base font-semibold">
      {{ label }}
    </span>
  </div>
</template>

<script>
    export default {
        name: "BaseRadioButton",
        props: {
            value: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                default: "",
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        emits: ["click"]
    }
</script>