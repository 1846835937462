import axios from 'axios'

export default {
  token: null,
  dsecret: null,
  storeDSecret (dsecret) {
    if (dsecret !== undefined) {
      localStorage.setItem('dsecret', dsecret)
      this.setDSecretHeader(dsecret)
      return true
    }
    return false
  },
  storeToken (token) {
    if (token !== undefined) {
      localStorage.setItem('accessToken', token)
      this.setAuthorizationHeader(token)
      return true
    }
    return false
  },
  storeTemporaryToken (token) {
    if (token !== undefined) {
      this.token = token
      this.setAuthorizationHeader(token)
      return true
    }
    return false
  },
  isAuthenticated () {
    const token = this.getAccessToken()
    return token != null && token !== undefined && token !== 'undefined'
  },
  getAccessToken () {
    return (localStorage.getItem('accessToken') !== '' && localStorage.getItem('accessToken') !== null && localStorage.getItem('accessToken') !== undefined) ? localStorage.getItem('accessToken') : sessionStorage.getItem('accessToken')
  },
  getDSecret () {
    return (localStorage.getItem('dsecret') !== '' && localStorage.getItem('dsecret') !== null && localStorage.getItem('dsecret') !== undefined) ? localStorage.getItem('dsecret') : null
  },
  setAuthorizationHeader (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  },
  setDSecretHeader (dsecret) {
    axios.defaults.headers.common['DSecret'] = `${dsecret}`
  },
  clearToken () {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('user')
  },
  refreshHeader () {
    if (this.isAuthenticated()) {
      this.setAuthorizationHeader(this.getAccessToken())
      this.setDSecretHeader(this.getDSecret())
    }
  }
}
