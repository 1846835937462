import { get } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/admin`

const getScripts = async (data) => get(`${url}/process-monitoring`, data)
const getOtherPageScripts = async (link, data) => get(`${link}`, data)

export default {
	getScripts,
	getOtherPageScripts
}