import {get, post, destroy, put, downloadBlob} from "../helpers/axiosHelper"

const url = `${process.env.VUE_APP_API_URL}`

const getCustomers = async(data) => get(`${url}/admin/customer-care/fetch-users?search=${data}`)
const getCustomer = async(id) => get(`${url}/admin/customer-care/user/${id}`)
const scrollCustomers = async(link) => get(`${link}`)
const getCountries = async() => get(`${url}/countries`)
const getCurrencies = async() => get(`${url}/currencies`)
const deleteCustomer = async (id) => destroy(`${url}/admin/customer-care/user/${id}/delete`)
const editCustomer = async (payload) => put(`${url}/admin/customer-care/user/${payload.id}/update-user`, payload)
const getVisits = async(id) => get(`${url}/admin/customer-care/user/${id}/visit`)
const getComments = async(id) => get(`${url}/admin/customer-care/user/${id}/comments`)
const addComment = async(payload) => post(`${url}/admin/customer-care/user/${payload.id}/comments`, payload)
const getHistoryLogs = async(id) => get(`${url}/admin/customer-care/user/${id}/history-logs`)
const scrollHistoryLogs = async(link) => get(`${link}`)
const exportCsv = async(id) => downloadBlob(`${url}/admin/customer-care/user/${id}/export-csv`)

export default {
    getCustomers,
	getCustomer,
	scrollCustomers,
	getCountries,
	getCurrencies,
	deleteCustomer,
	editCustomer,
	getVisits,
	getComments,
	addComment,
	getHistoryLogs,
	scrollHistoryLogs,
	exportCsv
}