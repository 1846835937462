<template>
  <div
    class="w-full "
    :class="small ? 'lg:w-3/12' : big ? 'lg:w-8/12' : full ? '' : 'lg:w-4/12'"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'RightColumn',
  props: {
    small: {
      type: Boolean,
      default: false
    },
    big: {
      type: Boolean,
      default: false
    },
    full: {
      type: Boolean,
      default: false
    }
  }
}
</script>
