<template>
  <div
    :class="`${padding} ${background} ${border} ${shadow} ${rounded} `"
    @click="$emit('click')"
  >
    <component
      :is="to ? 'RouterLink' : 'div'"
      :to="to ? to : undefined"
      class="h-full w-full"
    >
      <div
        :class="body"
      >
        <slot />
      </div>
    </component>
  </div>
</template>

<script>

export default {
  name: 'BaseCard',
  props: {
    to: { type: Object, default: () => {} },
    padding: { type: String, default: 'p-4' },
    body: { type: String, default: 'flex flex-col flex-grow h-full w-full' },
    background: { type: String, default: 'bg-white' },
    border: { type: String, default: 'border-none' },
    shadow: { type: String, default: 'shadow-none' },
    rounded: { type: String, default: 'rounded-xl' }
  },
  emits: ['click']
}
</script>
