<template>
  <button
    class="rounded-full bg-custom-purple-500 hover:bg-custom-purple-400 cursor-pointer text-white w-10 h-10 flex items-center justify-center"
    @click="$emit('click', $event)"
  >
    <slot />
  </button>
</template>

<script>

export default {
  name: 'CircleButton',
  props: {
  }
}
</script>
