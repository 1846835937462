<template>
  <RouterLink
    :to="'/'"
    class="flex items-center"
  >
    <Logo />
  </RouterLink>
</template>

<script>

export default {
  name: 'SmallLogo',
  props: {
    color: { default: 'white', type: String },
  }
}
</script>

<style lang="scss" scoped>
.logo {
  height: 1.875rem;
}
</style>
