<template>
  <div
    v-if="linkableAvatarOnly"
    class="flex"
  >
    <div
      class="inline"
      :class="alignment"
    >
      <Avatar
        :size="size"
        :default-class="defaultClass"
        :stock-user="stockUser"
        :company="company"
        :use-premium="usePremium"
        :use-reputation="useReputation"
      />
    </div>
    <slot />
  </div>
  <div
    v-else
    :to="linkableAvatar ? to : null"
    :class="alignment"
  >
    <Avatar
      :size="avatarSize"
      :default-class="customDefaultClass"
      :stock-user="stockUser"
      :company="company"
      :margins="margins"
      :use-premium="usePremium"
      :use-reputation="useReputation"
    />
    <slot />
  </div>
</template>

<script>
import Avatar from './Avatar.vue'

export default {
  name: 'AvatarSlot',
  components: {
    Avatar
  },
  props: {
    stockUser: { type: Object, default: () => { } },
    company: { type: Object, default: () => { } },
    size: { type: Number, default: 40 },
    defaultClass: { type: String, default: 'w-10 h-10' },
    alignment: { type: String, default: 'flex w-full relative' },
    margins: { type: String, default: 'mr-2' },
    linkableAvatar: { type: Boolean, default: false },
    linkableAvatarOnly: { type: Boolean, default: false },
    bigAvatar: { type: Boolean, default: false },
    useReputation: { type: Boolean, default: false },
    usePremium: { type: Boolean, default: false }
  },
  computed: {
    // to () {
    //   if (this.stockUser) {
    //     return { name: 'userFlow', params: { id: this.stockUser.id } }
    //   } else {
    //     return { name: 'companyOverview', params: { id: this.company.slug_url } }
    //   }
    // },
    avatarSize () {
      if (this.bigAvatar) {
        return 50
      }
      return this.size
    },
    customDefaultClass () {
      if (this.bigAvatar) {
        return 'w-12 h-12'
      }

      return this.defaultClass
    }
  }
}
</script>
