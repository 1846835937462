<template>
  <button
    class="flex justify-center items-center rounded-lg"
    :class="[defaultClass,background]"
    :disabled="locked===true ?'':false"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>
<script>
export default {
    name: 'ActionButton',
    props: {
        locked: { type: Boolean, default: false},
        background: { type: String, default: ' hover:bg-custom-purple-400 bg-custom-purple-500 text-white '},
        defaultClass: {type: String, default: 'px-4 py-1.5'}
    },
    emits: ['click'],
}
</script>