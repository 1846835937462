<template>
  <div class="flex flex-1 justify-between">
    <div class="w-11/12 flex flex-col justify-center">
      <div
        :class="(textSize) ? `${textSize} font-semibold text-left` : 'text-base font-semibold text-left'"
      >
        <div
          class="flex items-center flex-grow-0 dark:text-custom-purple-100"
        >
          <span :class="nameClass">{{ name }}</span> <VerifiedIcon
            :stock-user="stockUser"
            :use-reputation="useReputation"
          />
          <IPOBadge
            v-if="company && company.hasOwnProperty('company_type') && company.company_type === 'ipo'"
            class="ml-2"
          />
          <PrivateBadge
            v-if="company && company.hasOwnProperty('company_type') && company.company_type === 'private'"
            class="ml-2"
          />
        </div>
        <span class="inline dark:text-custom-purple-200"><slot /></span>
      </div>
      <div
        v-if="subtitle.length > 0"
        class="text-gray-400 font-medium -mt-1 dark:text-custom-purple-100"
        :class="[subtitleClass, (subtitleSize) ? `${subtitleSize}` : (textSize) ? `${textSize}` : 'text-sm']"
      >
        <div>{{ subtitle }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import VRuntimeTemplate from 'v-runtime-template'

export default {
  name: 'NameSubTitle',
  components: {
    
  },
  props: {
    name: { type: String, default: '' },
    stockUser: { type: Object, default: () => {} },
    company: { type: Object, default: () => {} },
    subtitle: { type: String, default: '' },
    subtitleSize: { type: String, default: '' },
    subtitleClass: { type: String, default: '' },
    nameClass: { type: String, default: '' },
    textSize: { type: String, default: '' },
    useReputation: { type: Boolean, default: false }
  },
  emits: ['redirect'],
  computed: {
    template () {
      return `<div>${this.subtitle}</div>`
    }
  },
  methods: {
    redirect (data) {
      this.$emit('redirect', data)
    }
  }
}
</script>
