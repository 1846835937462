<template>
  <div class="w-full mt-4 mb-2">
    <audio
      class="w-full"
      controls
    >
      <source :src="src">
    </audio>
  </div>
</template>

<script>
export default {
  name: 'BaseAudio',
  props: {
    src: { type: String, default: '' }
  }
}
</script>
