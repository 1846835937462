import { get } from "../helpers/axiosHelper";

const url = `${process.env.VUE_APP_API_URL}/monitor`;

const getStockUsers = async () => get(`${url}/stock-users-reports`);
const getCompaniesReports = async () => get(`${url}/company-reports`);
const getAverageSessionTime = async (data) => get(`${url}/average-session-user?interval=${data.interval}`);
const getActiveUsers = async (data) => get(`${url}/current-active-user?interval=${data.interval}`);
const getTotalVisitors = async (data) => get(`${url}/total-visitors?interval=${data.interval}`);
const getVisitorByDimension = async (data) => get(`${url}/visitors-by-dimension?interval=${data.interval}&dimension=${data.dimension}`);
const getPayingVsActivePremium = async (data) => get(`${url}/paying-vs-active?interval=${data.interval}`);
const getDropOfData = async (data) => get(`${url}/page-drop-off?interval=${data.interval}`);
const getMembersByCountry = async (data) => get(`${url}/members-by-country?interval=${data.interval}&limit=${data.limit}`);

export default {
    getStockUsers,
    getCompaniesReports,
    getAverageSessionTime,
    getActiveUsers,
    getTotalVisitors,
    getVisitorByDimension,
    getPayingVsActivePremium,
    getDropOfData,
    getMembersByCountry,
};
