<template>
  <div
    v-if="stockUser"
    class="w-6 h-4 flex items-center gap-2"
  >
    <Icon
      v-if="stockUser && stockUser.is_verified"
      :name="isModerator ? 'check-shield' : 'check-circle'"
      class="text-lg w-4 h-4"
      :class="isModerator ? 'text-green-500 star-shield' : 'text-blue-400 star-shield'"
      solid
    />
    <div
      v-if="useReputation"
      class="flex flex-shrink-0 rounded-xl p-4 h-0 px-2 star-margin bg-black text-white reputation-bubble font-reputation text-4xs"
    >
      <span>
        {{ (stockUser.reputation !== undefined) ? abbreviateInteger(stockUser.reputation) : 0 }} R
      </span>
    </div>
    <Icon
      v-if="stockUser && isSuperstar"
      name="star"
      class="text-sm w-3 h-3 text-yellow-400"
      solid
    />
    <Icon
      v-if="stockUser && stockUser.company_user === 1"
      name="buildings"
      class="text-xl w-3 h-3 text-green-500 star-shield"
      solid
    />
  </div>
</template>

<script>

var NumAbbreviate = require('number-abbreviate')

export default {
  name: 'VerifiedIcon',
  props: {
    stockUser: {
      type: Object,
      default: () => { }
    },
    useReputation: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isModerator () {
      if (!this.stockUser) return false
      if (!this.stockUser.role_list) return false
      return this.stockUser.role_list.includes('moderator')
    },
    isSuperstar () {
      if (!this.stockUser) return false
      if (!this.stockUser.role_list) return false
      return this.stockUser.role_list.includes('superstar')
    },
    isProfile () {
      if (!this.stockUser) return false
      if (!this.stockUser.role_list) return false
      return this.stockUser.role_list.includes('verified-profile')
    }
  },
  methods: {
    abbreviateInteger (number) {
      let abbr = new NumAbbreviate([' K', ' M', ' MD', ' T'])

      return String(abbr.abbreviate(number, 0))
    }
  }
}
</script>

<style lang="scss">
.star-shield {
  margin-top: -2px;
}

.reputation-bubble {
  line-height: 16px;
  margin-top: -2px;
}
</style>
