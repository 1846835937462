<template>
  <img
    :class="[
      orange ? 'orange' : '',
      white ? 'white' : '',
      purple ? 'purple' : '',
      green ? 'green' : 'purple',
    ]"
    :src="src"
  >
</template>

<script>
export default {
  name: "SvgImage",
  props: {
    src: {
      type: String,
      default: "",
    },
    orange: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    purple: {
      type: Boolean,
      default: false,
    },
    green: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.orange {
  filter: invert(42%) sepia(85%) saturate(728%) hue-rotate(335deg)
    brightness(93%) contrast(104%);
}
.white {
  filter: invert(99%) sepia(100%) saturate(0%) hue-rotate(352deg)
    brightness(105%) contrast(100%);
}
.purple {
  filter: invert(28%) sepia(12%) saturate(2131%) hue-rotate(236deg)
    brightness(95%) contrast(87%);
}
.green {
  filter: invert(71%) sepia(83%) saturate(2140%) hue-rotate(100deg)
    brightness(96%) contrast(89%);
}
</style>
