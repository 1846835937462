<template>
  <div
    v-if="isPremium"
    class="bg-custom-green-200 rounded-full flex items-center justify-center"
    :class="small ? 'w-4 h-4' : 'w-6 h-6'"
  >
    <img
      src="/img/crown.png"
      :class="small ? 'h-2 w-2' : 'h-3 w-3'"
    >
  </div>
</template>
  
  <script>
  
  export default {
	name: 'PremiumIcon',
	props: {
      isPremium: {
		type: Boolean,
		default: false
      },
      small: {
		type: Boolean,
		default: false
      }
	}
  }
  </script>
  
