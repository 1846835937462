<template>
  <div
    class="avatar-initials flex-shrink-0"
    :style="style"
    aria-hidden="true"
  >
    <span class="leading-none">{{ internalInitials }}</span>
  </div>
</template>

<script>
export default {
  name: 'AvatarInitials',
  props: {
    name: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 40
    },
    lighten: {
      type: Number,
      default: 80
    }
  },
  data () {
    return {
      backgroundColors: [
        '#2159f3',
        '#213df3',
        '#2121f3',
        '#4f21f3',
        '#8d21f3',
        '#2196F3',
        '#168ccc',
        '#f32159',
        '#167dcc',
        '#3416cc',
        '#56b875',
        '#56b889',
        '#56a1b8'
      ],
      imgError: false
    }
  },
  computed: {
    background () {
      return this.backgroundColor || this.randomBackgroundColor(this.name.length, this.backgroundColors)
    },
    fontColor () {
      return this.color || this.lightenColor(this.background, this.lighten)
    },
    style () {
      const style = {
        display: this.inline ? 'inline-flex' : 'flex',
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderRadius: '50%',
        lineHeight: `${this.size + Math.floor(this.size / 20)}px`,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        userSelect: 'none'
      }
      const imgBackgroundAndFontStyle = {
        background: `transparent url('${this.src}') no-repeat scroll 0% 0% / ${this.size}px ${this.size}px content-box border-box`
      }
      const initialBackgroundAndFontStyle = {
        backgroundColor: this.background,
        font: `${Math.floor(this.size / 2.5)}px/${this.size}px Helvetica, Arial, sans-serif`,
        color: this.fontColor
      }
      const backgroundAndFontStyle = this.isImage ? imgBackgroundAndFontStyle : initialBackgroundAndFontStyle
      Object.assign(style, backgroundAndFontStyle)
      return style
    },
    internalInitials () {
      const initials = this.initials || this.getInitials(this.name)
      return initials
    }
  },
  mounted () {
    this.$emit('initials', this.name, this.internalInitials)
  },
  methods: {
    randomBackgroundColor (seed, colors) {
      return colors[seed % colors.length]
    },
    lightenColor (hex, amt) {
      // From https://css-tricks.com/snippets/javascript/lighten-darken-color/

      if (hex[0] === '#') {
        hex = hex.slice(1)
      }

      const num = parseInt(hex, 16)
      let r = num >> 16
      let b = (num >> 8) & 0x00ff
      let g = num & 0x0000ff

      let brightness = r * 0.299 + g * 0.587 + b * 0.114

      return brightness > 180 ? '#000' : '#fff'
    },
    getInitials (name) {
      let parts = name.split(/[ -]/)
      let initials = ''
      for (var i = 0; i < parts.length; i++) {
        initials += parts[i].charAt(0)
      }
      if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, '')
      }
      initials = initials.substr(0, 2).toUpperCase()
      return initials
    }
  }
}
</script>
