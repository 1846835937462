import { get, post, destroy } from "../helpers/axiosHelper";

const url = `${process.env.VUE_APP_API_URL}`;

const getFiles = async (currentPage, searchText) =>
  get(
    `${url}/admin/fetch-all-documentations?page=${currentPage}&search=${searchText}`
  );
const getSingleFile = async (id) =>
  get(`${url}/admin/documentation/${id}/fetch`);
const createFile = async (data) =>
  post(`${url}/admin/create-documentation`, data);
const getDeveloperList = async () => get(`${url}/admin/fetch-developers-list`);
const assignUser = async (fileId, userId) =>
  post(`${url}/admin/documentation/${fileId}/attach/user/${userId}`);
const removeUser = async (fileId, userId) =>
  post(`${url}/admin/documentation/${fileId}/detach/user/${userId}`);
const updateFile = async (fileId, data) =>
  post(`${url}/admin/documentation/${fileId}/update-documentation`, data);
const deleteFile = async (fileId) =>
  destroy(`${url}/admin/documentation/${fileId}/delete-documentation`);

export default {
  getFiles,
  getSingleFile,
  createFile,
  getDeveloperList,
  assignUser,
  removeUser,
  updateFile,
  deleteFile,
};
