<template>
  <header
    class=" dark:bg-custom-purple-900 dark:text-primary-dark shadow-reverse dark:shadow-sm-dark z-20 w-full"
  />
</template>

<script>


// import MenuContainer from '@/modules/menu'

export default {
  name: 'HeaderInternal',
  props: {
    iosPadding: { type: Boolean, default: false },
    isLoggedIn: { type: Boolean, default: false },
    menu: { type: Boolean, default: false },
    showAppsMenu: { type: Boolean, default: false },
    unreadMessages: { type: Number, default: 0 },
    user: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
