<template>
  <div
    class="rounded-2xl text-xs py-1 px-3 text-center"
    :class="[
      green ? 'bg-custom-green-500 text-custom-purple-500' : '',
      gray ? 'bg-gray-300 text-gray-800' : '',
      purple ? 'bg-custom-purple-500 text-custom-purple-200' : '',
      orange ? 'bg-custom-orange-400 text-custom-purple-100' : '',
      yellow ? 'bg-custom-yellow-300 text-custom-purple-500' : '',
      red ? 'bg-custom-red-400 text-custom-purple-500' : '',
      smallPadding ? 'h-5 px-2' : 'py-1 px-3'
    ]"
    @click="$emit('click', $event)"
  >
    <slot />
  </div>
</template>
  
<script>
  
export default {
	name: 'Badge',
	props: {
      green: {
		type: Boolean,
		default: false
      },
      gray: {
		type: Boolean,
		default: false
      },
      purple: {
		type: Boolean,
		default: false
      },
      orange: {
		type: Boolean,
		default: false
      },
      yellow: {
		type: Boolean,
		default: false
      },
      red: {
		type: Boolean,
		default: false
      },
      smallPadding: {
		type: Boolean,
		default: false
      }
	}
}
</script>
