import { get, post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const pendingClaims = () => get(`${url}/sales-role/list/status/pending`)
const approvePendingClaim = (id) => post(`${url}/sales-role/action/approve/${id}`)
const rejectPendingClaim = (id) => post(`${url}/sales-role/action/deny/${id}`)
const topSales = () => get(`${url}/sales-role/list/top-sales-subscription`)
const performanceData = () => get(`${url}/sales-role/chart/performance`)
const filterOptions = () => get(`${url}/sales-role/filter/sales-filter-data`)
const filteredData = (data) => get(`${url}/sales-role/filter/fetch/sales-filter-data`, data)
const bulkApprove = (data) => post(`${url}/sales-role/filter/action/bulk-approve`, data)
const bulkReject = (data) => post(`${url}/sales-role/filter/action/bulk-deny`, data)
const listAllClaimsByPerson = (id) => get(`${url}/sales-role/list/sales-person/${id}`)
const getAllCompanyInstruments = (link, data) => post(`${link}`, data)
const officerPerfomanceData = (id) => get(`${url}/sales-role/chart/sales-performance/${id}`)
const claimCompany = (id) => get(`${url}/sales-role/action/claim/company/${id}`)

export default {
    pendingClaims,
    approvePendingClaim,
    rejectPendingClaim,
    topSales,
    performanceData,
    filterOptions,
    filteredData,
    bulkApprove,
    bulkReject,
    listAllClaimsByPerson,
    getAllCompanyInstruments,
    officerPerfomanceData,
    claimCompany
}