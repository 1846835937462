<template>
  <div
    class="flex flex-1 flex-row h-full bg-main-background font-standard font-medium font-base text-gray-900"
  >
    <SignInLayout v-if="isSignInLayout" />
    <Layout v-else>
      <router-view :key="$route.fullPath" />
    </Layout>
  </div>
</template>

<script>
import 'boxicons/css/boxicons.min.css'

import Layout from '@/layout/Layout.vue'
import SignInLayout from './layout/SignInLayout.vue';

export default {
  name: 'App',
  components: {
    Layout,
    SignInLayout
  },
  metaInfo: {
    title: 'Eucaps Admin',
    // titleTemplate: '%s | vue-meta Example App',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'An example Vue application with vue-meta.' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  computed: {
    isSignInLayout () {
      if (this.$route.meta && this.$route.meta.layout) {
        return this.$route.meta.layout === 'SignInLayout'
      }
      return false
    }
  }
}
</script>
