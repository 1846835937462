<template>
  <BigLogo
    v-if="big"
    :color="color"
  />
  <SmallLogo
    v-else
    :color="color"
  />
</template>

<script>

import SmallLogo from './_components/SmallLogo'
import BigLogo from './_components/BigLogo'

export default {
  name: 'LogoModule',
  components: {
    SmallLogo,
    BigLogo
  },
  props: {
    mode: { default: 'light', type: String },
    big: { type: Boolean, default: false },
    eucaps: { type: Boolean, default: false }
  },
  computed: {
    color () {
      if (this.mode === 'light') {
        return 'white'
      }
      return 'gray-500'
    }
  }
}
</script>
