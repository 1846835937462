<template>
  <div
    class="flex-shrink-0 rounded-full overflow-hidden"
    :class="[margin,size]"
  >
    <Flag
      :code="countryCode"
      class="vue-flag"
      gradient="top-down"
      :has-border="false"
    />
  </div>
</template>

<script>

export default {
  name: 'CountryFlag',
  props: {
    company: {
      type: Object,
      default: () => { }
    },
    tag: {
      type: String,
      default: 'country'
    },
    size: {
      type: String,
      default: 'w-6 h-6'
    },
    margin: {
      type: String,
      default: 'mr-2'
    }
  },
  computed: {
    countryCode () {
      if (this.company[this.tag] === 'GB') return 'GB-UKM'
      else return this.company[this.tag]
    }
  }
}
</script>

<style lang="scss">
 .vue-flag.flag.size-m {
    width: 100% !important;
    height: 100% !important;
 }
</style>
