import { get, post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/incidents`
const fetch = async () => get(`${url}`)
const fetchIncident = async (id) => get(`${url}/${id}`)
const scroll = async (link) => get(`${link}`)
const fetchIncidentAnswers = async (id) => get(`${url}/${id}/get-answers`)
const fetchAssignees = async () => get(`${url}/get-assignees`)
const fetchFilteredIncidents = async (payload) => get(`${url}`, payload)
const changeResponsible = async (payload) => post(`${url}/change-responsible`, payload)
const takeResponsibilityForIncident = async (id) => post(`${url}/${id}/responsible`)
const setActionToUser = async (id, payload) => post(`${url}/${id}/change-action`, payload)
const setIncidentStatus = async (id, payload) => post(`${url}/${id}/change-status`, payload)
const setMultipleIncidentStatuses = async (payload) => post(`${url}/update-incidents-status`, payload)
const postComment = async (id, payload) => post(`${url}/${id}/comments`, payload) 
const sendAnswer = async (id, payload) => post(`${url}/${id}/send-answer`, payload)
export default {
  fetch,
  fetchIncident,
  scroll,
  fetchIncidentAnswers,
  fetchAssignees,
  fetchFilteredIncidents,
  changeResponsible,
  takeResponsibilityForIncident,
  setActionToUser,
  setIncidentStatus,
  postComment,
  sendAnswer,
  setMultipleIncidentStatuses
}
